<template>
    <div class="teachingPatro">
        <div class="flex-box">
            <div class="left">
                <el-button type="success" @click="addCoursePatrol">+新增巡查</el-button>
                <el-button type="info" @click="addCoursePatrol">批量导出</el-button>
            </div>
        </div>
        <el-table
            :data="coursePatrolList"
            >
            <el-table-column
                label="巡查人"
                prop="mname"
                align="center"></el-table-column>
            <el-table-column
                label="巡查时间"
                prop="check_time"
                align="center"></el-table-column>
            <el-table-column
                label="操作"
                width="500px"
                prop="course"
                align="center">
                <template slot-scope="scope">
                    <el-button
                    type="primary"
                    @click="lookTeachingPatrolDetails(scope.row)">详情
                    </el-button>
                    <el-button
                    type="success"
                    @click="editPatrol(scope.row)">修改
                    </el-button>
                    <el-button
                    type="danger"
                    @click="deletePatrol(scope.row.id)">删除
                    </el-button>
                </template>
            </el-table-column>
            <!-- <el-table-column
                label="教师姓名"
                prop="mname"
                align="center"></el-table-column> -->
        </el-table>
        <el-pagination background
            layout="prev,pager,next"
            :page-count="page.pageCount"
            :page-size="page.currentPage"
            @current-change="loadCoursePatrol"></el-pagination>

        <el-dialog
            :title="addCoursePatrolForm.managerid ? '修改课堂巡查' : '添加课堂巡查'"
            :visible.sync="addCoursePatrolFormDialogVisible"
            width="600px">
            <div class="center">
                <el-form
                :model="addCoursePatrolForm"
                ref="addCoursePatrolFormRef"
                label-width="100px"
                :rules="addCoursePatrolFormRules">
                    <!-- 巡查人 -->
                    <el-form-item label="巡查人：" prop="managerid">
                        <el-select v-model="addCoursePatrolForm.managerid" clearable placeholder="请选择巡查人">
                            <el-option
                            v-for="item in teachersList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <!-- 巡查时间 -->
                    <el-form-item label="巡查时间：" prop="managerid">
                        <el-date-picker
                            value-format="yyyy-MM-dd"
                            v-model="addCoursePatrolForm.check_time"
                            type="date"
                            placeholder="选择巡查时间">
                        </el-date-picker>
                    </el-form-item>
                </el-form>
            </div>
            <!-- 确认与取消 -->
            <span slot="footer" class="dialog-footer">
                <el-button @click="addCoursePatrolFormDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="addCoursePatrolConfirm">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name:'teachingPatro',
    data(){
        return {
            page: { currentPage: 0, pageCount: 1 },
            teachersList:[],
            addCoursePatrolForm:{
                id:'',
                managerid:'',
                check_time:'',
            },
            addCoursePatrolFormRules:{
                managerid: [{required: true}],
                check_time: [{required: true}],
            },
            addCoursePatrolFormDialogVisible:false,
            examinerTypeList:[
                {type:1,name:'请假审批'},
                {type:2,name:'报修审批'},
                {type:3,name:'用车审批 '},
                {type:4,name:'报修人员'},
                {type:5,name:'年度考核'},
                {type:6,name:'公文管理'},
            ],
            coursePatrolList:[],
        }
    },
    created(){
        this.getTeachersList()
        this.loadCoursePatrol(1)
    },
    methods:{
        // 得到教师列表
        getTeachersList(){
            // 教师列表接口
            this.$post('/media/queryAllManager.do')
            .then(res => {
                console.log(res.data)
                this.teachersList = res.data
            })
        },
        // 获取初始页面数据
        loadCoursePatrol(page){
            const that = this;
            this.$post("/media/queryInspection.do",{
                indexNo:1,
                name:'',
                start_time:'',
                end_time:''
            })
            .then((res) => {
                // console.log(res.data)
                this.page.currentPage = page
                this.page.pageCount = res.data.pageCount
                this.page.currentPage = res.data.currentPage

                that.coursePatrolList = res.data.rows;
                console.log(that.coursePatrolList)
            })
            .catch((err) => {
                    that.$message({
                    type: "warning",
                    message: err.message,
                });
            });
        },
        // 添加课堂巡查
        addCoursePatrol(){
            this.addCoursePatrolFormDialogVisible = true
            this.$nextTick(() => {
              console.log(1)
                this.$refs.addCoursePatrolFormRef.resetFields()
            })
        },
        // 编辑
        editPatrol(row){
		  // 显示对话框
		  this.addCoursePatrolFormDialogVisible = true
            // 把数据赋值给编辑表单
            this.$nextTick(()=>{
                this.addCoursePatrolForm.id = row.id
                this.addCoursePatrolForm.check_time = row.check_time
                this.addCoursePatrolForm.managerid = row.managerid
            })
        },
        // 表单提交
        addCoursePatrolConfirm(){
            this.$refs.addCoursePatrolFormRef.validate(validate => {
                if(validate){
                    // 编辑审批人表单提交
                    if(this.addCoursePatrolForm.id){
                        this.$post('/media/updateInspection.do',this.addCoursePatrolForm)
                        .then(res => {
						  this.$message.success('编辑成功')
                            this.addCoursePatrolFormDialogVisible = false;
                            // 更新当前页数据列表
                            this.loadCoursePatrol()
                        })
                        return
                    }
                    // 添加审批人表单提交
                    const data = {
                        check_time:this.addCoursePatrolForm.check_time,
                        managerid:this.addCoursePatrolForm.managerid,
                    }
                    console.log(data)
                    this.$post('/media/insertInspection.do',data)
                    .then(res => {
					  this.$message.success('添加成功')
                        this.addCoursePatrolFormDialogVisible = false;
                        this.loadCoursePatrol(1);
                        console.log(res)
                    })
                    .catch((err) => {
                        // that.$message.error(err.message.data);
                    });
                }

            })
        },
        // 删除
        deletePatrol(id){
            const that = this;
            this.$confirm("是否删除课堂巡查?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
            .then(() => {
                this.$post("/media/deleteInspection.do", { id })
                .then((res) => {
                that.$message({
                    type: "success",
                    message: "删除成功!",
                });
                that.loadCoursePatrol(this.page.currentPage);
                })
                .catch((err) => {
                that.$message.error(err.message)
                });
            })
            .catch(() => {
                this.$message({
                type: "info",
                message: "已取消删除",
                });
            });
        },
        // 跳转到教学巡查详情页
        lookTeachingPatrolDetails(row){
            console.log(row)
            // return
            this.$router.push({
                name:'teachingPatrolDetails',
                query:{
                    id:row.id
                }
            })
        }
    }
}
</script>

<style scoped>
.input-box {
  width: 180px;
  margin: 0 5px;
}

.flex-box {
  display: flex;
  justify-content: space-between;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.picker {
  margin-left: 10px;
}
</style>
